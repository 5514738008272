import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LogoFA from "../../atoms/SVG/LogoFA";
import Burger from "../../atoms/Burger/Burger";
import "./Nav.scss";

export default function Nav() {
  const [topPosition, setTopPosition] = useState(undefined);

  useEffect(() => {
    if (window.pageYOffset === 0) {
      setTopPosition(true);
    } else setTopPosition(false);
    function onScroll() {
      const currentPosition = window.pageYOffset;
      if (currentPosition > 0) {
        setTopPosition(false);
      } else {
        setTopPosition(true);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const SwitchNav = () => {
    const location = useLocation();
    const { pathname } = location;
    if (pathname === "/") {
      return (
        <nav className={`${topPosition ? "homeNav" : "homeNavBackground"}`}>
          <HashLink
            to="/#"
            className={`${topPosition ? "homeNav" : "homeNavBackground"}_logo`}
          >
            <LogoFA />
          </HashLink>
          <Burger />
        </nav>
      );
    }
    return (
      <nav className="nav">
        <HashLink to="/#" className="nav_logo">
          <LogoFA />
        </HashLink>
        <Burger />
      </nav>
    );
  };

  return <SwitchNav />;
}
