/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import Section from "../../organisms/Section/Section";
import Services from "../../organisms/Services/Services";
import ContactForm from "../../organisms/Form/ContactForm";
import PartenairesCarrousel from "../../organisms/PartenairesCarrousel/PartenairesCarrousel";
import "./Home.scss";

export default function Home() {
  return (
    <>
      <section className="home">
        <img
          className="home_illustration"
          src={`${process.env.PUBLIC_URL}/images/service_sound_design.jpg`}
          alt="Un vieux tourne disque illustrant la page d'accueil"
        />

        <div className="home_title">
          <h1 className="home_title_h1">
            Fredelux <span className="home_title_span">Agency</span>
          </h1>
        </div>
      </section>
      <Section
        id="agence"
        headerTitle="L'agence"
        headerIllustration={`${process.env.PUBLIC_URL}/images/ballons.jpg`}
        headerAlt="Une illustration de la section Agence"
        descriptionTitle="Qui sommes-nous ?"
        descriptionParagraphe={`C’est le fruit d’une longue réflexion et d’une grande volonté de vous faire atteindre l’excellence.
        
        Nous sommes prestataire de services spécialisé dans la conception et l’organisation d’événements. Nos services s’adressent aux professionnels, (entreprises, hôtelleries...) aussi bien qu’aux particuliers.

        Confiez-nous vos projets, nous saurons vous apporter la solution la plus adaptée à vos besoins grâce à notre équipe d’experts aux compétences indispensables à la réussite de votre événement. Nous créons des événements sur mesure, adaptés à vos souhaits et besoins pour un résultat parfait.
        
        Nous vous accompagnerons tout au long de votre projet, et vous proposerons d’écrire ensemble le cahier des charges le plus adapté à vos besoins. Nous faisons preuve d’une grande flexibilité ainsi que d’une écoute attentive.`}
      />
      <Services />
      <Section
        id="contact"
        headerTitle="Contact"
        headerIllustration={`${process.env.PUBLIC_URL}/images/contact.jpg`}
        headerAlt="Une illustration de la section Contact"
        descriptionTitle="Comment nous contacter ?"
        descriptionParagraphe="Vous avez un projet et souhaitez bénéficier de notre expérience  pour que la réalisation soit une belle réussite ? N’hésitez-pas à nous faire une demande de rendez-vous en utilisant le formulaire ci-dessous afin que vos idées se concrétisent au mieux."
        option={<ContactForm />}
      />
      <PartenairesCarrousel />
    </>
  );
}
