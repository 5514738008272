import * as React from "react";

function Soundcloud() {
  return (
    <svg height={512} viewBox="0 0 24 24" width={512}>
      <title>Soundcloud</title>
      <path
        fill="currentColor"
        d="M20.25 11c-.267 0-.529.029-.782.082C19.131 7.673 16.246 5 12.75 5a.75.75 0 00-.75.75v12c0 .414.336.75.75.75h7.5A3.753 3.753 0 0024 14.75 3.754 3.754 0 0020.25 11zM9 7.25v10.5a.75.75 0 001.5 0V7.25a.75.75 0 00-1.5 0zM6 10.25v7.5a.75.75 0 001.5 0v-7.5a.75.75 0 00-1.5 0zM3 17.75a.75.75 0 001.5 0v-7.5a.75.75 0 00-1.5 0zM.75 18.5a.75.75 0 00.75-.75v-4.5a.75.75 0 00-1.5 0v4.5c0 .414.336.75.75.75z"
      />
    </svg>
  );
}

export default Soundcloud;
