/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import validateForm from "./ValidateContactForm";
import "./ContactForm.scss";

export default function ContactForm({ bookingText }) {
  const [messageSend, setMessageSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputForm, setInputForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    const subject = Object.values({ bookingText })[0];
    setInputForm({ ...inputForm, subject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingText]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setInputForm({ ...inputForm, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors(validateForm(inputForm));
    setIsSubmiting(true);
  };

  useEffect(() => {
    const sendMail = async () => {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/sendMail`,
        inputForm,
        setLoading(true)
      );
      const subject = Object.values({ bookingText })[0];
      setLoading(false);
      setInputForm({
        firstName: "",
        lastName: "",
        email: "",
        tel: "",
        subject,
        message: "",
      });
      setMessageSend(true);
      setTimeout(() => {
        setMessageSend(false);
      }, 5000);
    };
    if (Object.keys(errors).length === 0 && isSubmiting) {
      sendMail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmiting, errors]);

  const SuccesSend = () => {
    if (loading) {
      return (
        <div className="form_successSend">
          <h3>Chargement ...</h3>
        </div>
      );
    }
    if (!messageSend) {
      return null;
    }
    return (
      <div className="form_successSend">
        <h3>&#10003; Message envoyé avec succès !</h3>
      </div>
    );
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="input_container">
        <div className="input_text">
          <label htmlFor="first-name" className="input_text_label">
            Nom
          </label>
          <input
            className="input_text_field"
            type="text"
            name="firstName"
            id="firstName"
            placeholder="Nom *"
            value={inputForm.firstName}
            onChange={handleChange}
          />
          {errors.firstName ? (
            <span className="form_errors">{errors.firstName}</span>
          ) : null}
        </div>

        <div className="input_text">
          <label htmlFor="lastName" className="input_text_label">
            Prénom
          </label>
          <input
            className="input_text_field"
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Prénom *"
            value={inputForm.lastName}
            onChange={handleChange}
          />
          {errors.lastName ? (
            <span className="form_errors">{errors.lastName}</span>
          ) : null}
        </div>
      </div>
      <div className="input_container">
        <div className="input_email">
          <label htmlFor="email" className="input_email_label">
            Email
          </label>
          <input
            className="input_email_field"
            type="email"
            name="email"
            id="email"
            placeholder="Email *"
            value={inputForm.email}
            onChange={handleChange}
          />
          {errors.email ? (
            <span className="form_errors">{errors.email}</span>
          ) : null}
        </div>
        <div className="input_tel">
          <label htmlFor="tel" className="input_tel_label">
            Téléphone
          </label>
          <input
            className="input_tel_field"
            type="tel"
            name="tel"
            id="tel"
            placeholder="Téléphone *"
            value={inputForm.tel}
            onChange={handleChange}
          />
          {errors.tel ? (
            <span className="form_errors">{errors.tel}</span>
          ) : null}
        </div>
      </div>
      <div className="input_select">
        <label htmlFor="subject" className="input_select_label">
          Sujet
        </label>
        <div className="input_select_textField">
          <h4>Sujet :</h4>
        </div>

        <div className="input_select_container">
          <select
            className="input_select_field"
            name="subject"
            id="subject"
            value={inputForm.subject}
            onChange={handleChange}
          >
            <option value={bookingText}>{bookingText}</option>
            <option value="Sound Design">Sound Design</option>
            <option value="Autre">Autre</option>
          </select>
        </div>
      </div>

      <div className="input_textarea">
        <label htmlFor="message" className="input_textarea_label">
          Sujet
        </label>
        <div className="input_textarea_containerField">
          <textarea
            className="input_textarea_field"
            name="message"
            id="message"
            placeholder="Message *"
            value={inputForm.message}
            onChange={handleChange}
          />
          {errors.message ? (
            <span className="form_errors">{errors.message}</span>
          ) : null}
        </div>
      </div>

      <div className="input_submit">
        <button className="input_submit_button" type="submit">
          <p>
            Envoyer <span>&#10095;</span>
          </p>
        </button>
      </div>
      <SuccesSend />
    </form>
  );
}

ContactForm.propTypes = {
  bookingText: PropTypes.string,
};

ContactForm.defaultProps = {
  bookingText: "Booking DJ",
};
