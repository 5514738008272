import React from "react";

import SocialNetwork from "../../atoms/Buttons/SocialNetwork/SocialNetwork";
import Facebook from "../../atoms/SVG/Facebook";
import Instagram from "../../atoms/SVG/Instagram";
import Soundcloud from "../../atoms/SVG/Soundcloud";

import "./socialsNetworkList.scss";

export default function SocialNetworkList() {
  return (
    <div className="socialsNetworkList">
      <SocialNetwork
        href="https://www.facebook.com/fredeluxagency"
        logo={<Facebook />}
      />
      <SocialNetwork
        href="https://www.instagram.com/fredelux.peewee/"
        logo={<Instagram />}
      />
      <SocialNetwork
        href="https://soundcloud.com/dj-fredelux"
        logo={<Soundcloud />}
      />
    </div>
  );
}
