import * as React from "react";

function Mixcloud() {
  return (
    <svg viewBox="0 0 24 24">
      <title>Mixcloud</title>
      <g fill="currentColor">
        <path d="M19.33 9.93a.5.5 0 00-.16.69 5.44 5.44 0 010 5.77.5.5 0 00.85.53 6.44 6.44 0 000-6.82.5.5 0 00-.69-.17zM22.42 8.22a.5.5 0 10-.83.55 8.58 8.58 0 010 9.45.5.5 0 10.83.55 9.58 9.58 0 000-10.55zM16.27 10.78a.5.5 0 00-.31.41 6.52 6.52 0 01-.42 1.73.51.51 0 01-.65.28.5.5 0 01-.28-.65 5.51 5.51 0 00-3.06-7.15h-.06A5.45 5.45 0 005 7.42a.5.5 0 00.29.77 4.91 4.91 0 012.25 1.27.5.5 0 010 .71.51.51 0 01-.71 0 4 4 0 00-2.15-1.1A3.82 3.82 0 004 9a4 4 0 000 8h10.5a3.49 3.49 0 002.28-6.14.5.5 0 00-.51-.08z" />
      </g>
    </svg>
  );
}

export default Mixcloud;
