import React from "react";
import { HashLink } from "react-router-hash-link";
import "./ContactLink.scss";

export default function ContactLink() {
  return (
    <div className="contact">
      <div className="contact_line" />
      <HashLink className="contact_link" to="/#contact">
        Nous contacter
      </HashLink>
    </div>
  );
}
