import React from "react";

import "./Footer.scss";

import SocialNetworkList from "../../molecules/SocialNetworkList/SocialNetworkList";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer_socials">
        <SocialNetworkList />
      </div>
      <address className="adresseBalise">
        <div className="footer_tel">
          <a href="tel:+33634648754"> +33 (0) 6 34 64 87 54 </a>
          <a href="tel:+33177133914"> +33 (0) 1 77 13 39 14 </a>
        </div>
        <h3 className="footer_adresse">
          34 rue du Faubourg Saint-Honorée 75008 Paris
        </h3>
        <a href="mailto:contact@fredeluxagency.com" className="footer_mail">
          contact@fredeluxagency.com
        </a>
      </address>
      <h4 className="footer_copyright">
        Copyright&#x00A9;2020 Fredelux Agency
      </h4>
    </footer>
  );
}
