/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import CloseButton from "../../atoms/Buttons/CloseButton/CloseButton";
import SocialNetwork from "../../atoms/Buttons/SocialNetwork/SocialNetwork";
import Soundcloud from "../../atoms/SVG/Soundcloud";
import Facebook from "../../atoms/SVG/Facebook";
import Instagram from "../../atoms/SVG/Instagram";
import Spotify from "../../atoms/SVG/Spotify";
import Beatport from "../../atoms/SVG/Beatport";
import Mixcloud from "../../atoms/SVG/Mixcloud";
import Youtube from "../../atoms/SVG/Youtube";
import ContactForm from "../../organisms/Form/ContactForm";

import "./DjCard.scss";

export default function DjCard() {
  const { url_name } = useParams();
  const [djDetails, setDjDetails] = useState({});
  const [musicalGenresList, setMusicalGenresList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const DjData = await axios(
        `${process.env.REACT_APP_API_URL}/djs/${url_name}`
      );
      setDjDetails(DjData.data);
      setMusicalGenresList(DjData.data.musical_genres);
    };
    fetchData();
  }, [url_name]);

  const HideShowSouncloud = () => {
    if (djDetails.soundcloud !== null) {
      return (
        <SocialNetwork href={djDetails.soundcloud} logo={<Soundcloud />} />
      );
    }
    return null;
  };

  const HideShowFacebook = () => {
    if (djDetails.facebook !== null) {
      return <SocialNetwork href={djDetails.facebook} logo={<Facebook />} />;
    }
    return null;
  };

  const HideShowInstagram = () => {
    if (djDetails.instagram !== null) {
      return <SocialNetwork href={djDetails.instagram} logo={<Instagram />} />;
    }
    return null;
  };

  const HideShowSpotify = () => {
    if (djDetails.spotify !== null) {
      return <SocialNetwork href={djDetails.spotify} logo={<Spotify />} />;
    }
    return null;
  };

  const HideShowBeatport = () => {
    if (djDetails.beatport !== null) {
      return <SocialNetwork href={djDetails.beatport} logo={<Beatport />} />;
    }
    return null;
  };

  const HideShowMixcloud = () => {
    if (djDetails.mixcloud !== null) {
      return <SocialNetwork href={djDetails.mixcloud} logo={<Mixcloud />} />;
    }
    return null;
  };

  const HideShowYoutube = () => {
    if (djDetails.youtube !== null) {
      return <SocialNetwork href={djDetails.youtube} logo={<Youtube />} />;
    }
    return null;
  };

  return (
    <section className="djCardContainer">
      <div className="djCardContainer_title">
        <h2>Booking DJ</h2>
      </div>
      <div className="djCard">
        <div className="djCard_closeButton">
          <CloseButton linkUrl="/booking" />
        </div>
        <div className="djCard_header">
          <img
            className="djCard_header_profilePicture"
            src={djDetails.profile_picture}
            alt={`Une illustration de ${djDetails.name}`}
          />
          <div className="djCard_header_informations">
            <h3 className="djCard_header_informations_name">
              {djDetails.name}
            </h3>
            <div className="djCard_header_informations_musicalGenres">
              {musicalGenresList.map((musicalGenre) => (
                <h4
                  className="djCard_header_informations_musicalGenres_item"
                  key={musicalGenre}
                >
                  {musicalGenre}
                </h4>
              ))}
            </div>
            <div className="djCard_header_informations_socialNetworks">
              <HideShowSouncloud />
              <HideShowFacebook />
              <HideShowInstagram />
              <HideShowSpotify />
              <HideShowBeatport />
              <HideShowMixcloud />
              <HideShowYoutube />
            </div>
          </div>
          <div className="djCard_header_bookingButton">
            <a
              href="#bookingForm"
              className="djCard_header_bookingButton_button"
              type="button"
            >
              Bookez-moi
            </a>
          </div>
        </div>
        <div className="djCard_description">
          <div className="djCard_description_titles">
            <div className="djCard_description_titles_line" />
            <h5 className="djCard_description_titles_text" to="/contact">
              Biographie
            </h5>
          </div>
          <p className="djCard_description_paragraphe">{djDetails.biography}</p>
          <div className="djCard_description_titles" id="bookingForm">
            <div className="djCard_description_titles_line" />
            <h5 className="djCard_description_titles_text" to="/contact">
              Contacter {djDetails.name}
            </h5>
          </div>
          {djDetails && (
            <ContactForm bookingText={`Booker ${djDetails.name}`} />
          )}
        </div>
      </div>
    </section>
  );
}
