export default function validateContactForm(values) {
  const errors = {};
  const emailRegex = /\S+@\S+\.\S+/;
  const telRegex = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/i;
  const notNumberRegex = /[^0-9]/g;

  if (!values.firstName.trim()) {
    errors.firstName = "Veuillez renseigner votre nom";
  } else if (
    typeof values.firstName !== "string" ||
    !notNumberRegex.test(values.firstName)
  ) {
    errors.firstName = "Votre nom doit être une chaine de caractères";
  }

  if (!values.lastName.trim()) {
    errors.lastName = "Veuillez renseigner votre prénom";
  } else if (
    typeof values.lastName !== "string" ||
    !notNumberRegex.test(values.lastName)
  ) {
    errors.lastName = "Votre prénom doit être une chaine de caractères";
  }

  if (!values.email.trim()) {
    errors.email = "Veuillez renseigner une adresse email";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "L'adresse email est invalide";
  }

  if (!values.tel.trim()) {
    errors.tel = "Veuillez renseigner un numéro de téléphone";
  } else if (!telRegex.test(values.tel)) {
    errors.tel = "Le numéro de téléphone est invalide";
  }

  if (!values.message.trim()) {
    errors.message = "Vous n'avez pas fait de demande";
  } else if (values.message.length < 6) {
    errors.message = "Votre demande doit faire au moins 6 caractères";
  }

  return errors;
}
