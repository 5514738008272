import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Booking from "./pages/Booking/Booking";
import SoundDesign from "./pages/SoundDesign/SoundDesign";
import DjCard from "./pages/DjCard/DjCard";
import NoMatch from "./pages/NoMatch/NoMatch";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/booking" component={Booking} />
      <Route exact path="/booking/:url_name" component={DjCard} />
      <Route exact path="/sound-design" component={SoundDesign} />
      <Route path="*" component={NoMatch} />
    </Switch>
  );
}
