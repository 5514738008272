import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Burger.scss";

export default function Burger() {
  const [topPosition, setTopPosition] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [className, setClassName] = useState("rightNav");
  const location = useLocation();

  useEffect(() => {
    if (window.pageYOffset === 0) {
      setTopPosition(true);
    } else setTopPosition(false);

    const { pathname } = location;
    if (window.pageYOffset === 0 && pathname === "/") {
      setClassName("rightNavHomeTop");
    } else setClassName("rightNav");

    function onScroll() {
      const currentPosition = window.pageYOffset;
      if (currentPosition > 0) {
        setTopPosition(false);
      } else {
        setTopPosition(true);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [location]);

  const switchOpen = () => {
    const { pathname } = location;
    setOpen(!open);
    if (open && pathname === "/" && !topPosition) {
      setClassName("rightNav");
    } else if (open && pathname !== "/") {
      setClassName("rightNav");
    } else if (open && pathname === "/" && topPosition) {
      setClassName("rightNavHomeTop");
    } else if (!open && pathname === "/" && topPosition) {
      setClassName("rightNavHomeTop_Show");
    } else if (!open && pathname === "/" && !topPosition) {
      setClassName("rightNav_Show");
    } else if (!open && pathname !== "/") {
      setClassName("rightNav_Show");
    }
  };

  const Nav = () => {
    if (!open) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div className="burger" role="button" tabIndex={0} onClick={switchOpen}>
          <div className="burger_barre1" />
          <div className="burger_barre2" />
          <div className="burger_barre3" />
          <div className="burger_barre4" />
        </div>
      );
    }
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className="burger_close"
        role="button"
        tabIndex={0}
        onClick={switchOpen}
      >
        <div className="burger_close_barre1" />
        <div className="burger_close_barre2" />
      </div>
    );
  };

  const click = () => {
    setClassName("rightNav");
    setOpen(!open);
  };

  return (
    <>
      <div className={className}>
        <HashLink to="/#agence" onClick={click}>
          <h2>L'agence</h2>
        </HashLink>
        <HashLink to="/booking/#" onClick={click}>
          <h2>Booking DJ</h2>
        </HashLink>
        <HashLink to="/sound-design/#" onClick={click}>
          <h2>Sound Design</h2>
        </HashLink>
        <HashLink to="/#contact" onClick={click}>
          <h2>Contact</h2>
        </HashLink>
      </div>
      <Nav />
    </>
  );
}
