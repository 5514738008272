import React from "react";
import "./NoMatch.scss";
import { useHistory } from "react-router-dom";

export default function NoMatch() {
  const history = useHistory();
  const returnHome = () => {
    history.push("/");
  };

  return (
    <div className="noMatch">
      <h2 className="noMatch_title">404</h2>
      <h3 className="noMatch_description">
        Oops, la page demandée n'a pas été trouvée...
      </h3>
      <button className="noMatch_buttonHome" onClick={returnHome} type="button">
        <span>&#10094;</span>
        Revenir à la page d'accueil
      </button>
    </div>
  );
}
