import React from "react";

import PropTypes from "prop-types";

import "./Section.scss";

export default function Section({
  id,
  headerTitle,
  headerIllustration,
  headerAlt,
  descriptionTitle,
  descriptionParagraphe,
  option,
}) {
  return (
    <section className="section" id={id}>
      <div className="section_header">
        <div className="section_header_background" />
        <h2 className="section_header_title">{headerTitle}</h2>
        <div className="section_header_illustration">
          <img src={headerIllustration} alt={headerAlt} />
        </div>
      </div>
      <div className="section_description">
        <div className="section_description_container">
          <h3 className="section_description_container_title">
            {descriptionTitle}
          </h3>
          <p className="section_description_container_paragraphe">
            {descriptionParagraphe}
          </p>
          {option}
        </div>
      </div>
    </section>
  );
}

Section.propTypes = {
  id: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  headerIllustration: PropTypes.string.isRequired,
  headerAlt: PropTypes.string.isRequired,
  descriptionTitle: PropTypes.string.isRequired,
  descriptionParagraphe: PropTypes.string.isRequired,
  option: PropTypes.objectOf(PropTypes.any),
};

Section.defaultProps = {
  id: null,
  option: null,
};
