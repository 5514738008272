import React from "react";

import PropTypes from "prop-types";

import "./SocialNetwork.scss";

export default function SocialNetwork({ href, logo }) {
  return (
    <a className="socialNetwork" href={href}>
      {logo}
    </a>
  );
}

SocialNetwork.propTypes = {
  href: PropTypes.string,
  logo: PropTypes.objectOf(PropTypes.any).isRequired,
};

SocialNetwork.defaultProps = {
  href: null,
};
