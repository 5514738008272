import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import Section from "../../organisms/Section/Section";
import ContactLink from "../../atoms/Link/ContactLink/ContactLink";
import "./Booking.scss";

export default function Booking() {
  const [djsList, setDjsList] = useState([]);
  const [musicalGenres, setMusicalGenres] = useState([]);
  const [musicalGenreSelected, setMusicalGenreSelected] = useState("All");
  const [open, setOpen] = useState(false);
  const [className, setClassName] = useState("containerButtonHidden");
  const [search, setSearch] = useState(false);

  const inputChoice = (event) => {
    setMusicalGenreSelected(event.target.value);
    setSearch(true);
    setClassName("containerButtonHidden");
    setOpen(false);
  };

  const openMusicalGenres = () => {
    setOpen(!open);
    if (!open) {
      return setClassName("containerButton");
    }
    return setClassName("containerButtonHidden");
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchDjs = await axios(`${process.env.REACT_APP_API_URL}/djs`);
      const fetchMusicalGenres = await axios(
        `${process.env.REACT_APP_API_URL}/musicalgenres`
      );
      setDjsList(fetchDjs.data);
      setMusicalGenres(fetchMusicalGenres.data);
    };
    fetchData();
  }, []);

  const djFilterArray = djsList.filter((dj) => {
    const hasGenre = dj.musical_genres.find(
      (element) => element === musicalGenreSelected
    );
    if (hasGenre) {
      return dj;
    }
    return hasGenre;
  });

  const SwitchArray = () => {
    if (search) {
      return (
        <section>
          <div className="djsList">
            {djFilterArray.map((dj) => (
              <HashLink
                className="djsList_card"
                to={`/booking/${dj.url_name}/#`}
                key={dj.url_name}
              >
                <img
                  className="djsList_card_profilePicture"
                  src={dj.profile_picture}
                  alt={`Une illustration de ${dj.name}`}
                />

                <div className="djsList_card_informations">
                  <h3 className="djsList_card_informations_name">{dj.name}</h3>
                  <div className="djsList_card_informations_musicalGenres">
                    {dj.musical_genres.map((musicalGenre) => (
                      <h4
                        className="djsList_card_informations_musicalGenres_item"
                        key={musicalGenre}
                      >
                        {musicalGenre}
                      </h4>
                    ))}
                  </div>
                </div>
              </HashLink>
            ))}
          </div>
        </section>
      );
    }
    return (
      <section>
        <div className="djsList">
          {djsList.map((dj) => (
            <HashLink
              className="djsList_card"
              to={`/booking/${dj.url_name}/#`}
              key={dj.url_name}
            >
              <img
                className="djsList_card_profilePicture"
                src={dj.profile_picture}
                alt={`Une illustration de ${dj.name}`}
              />

              <div className="djsList_card_informations">
                <h3 className="djsList_card_informations_name">{dj.name}</h3>
                <div className="djsList_card_informations_musicalGenres">
                  {dj.musical_genres.map((musicalGenre) => (
                    <h4
                      className="djsList_card_informations_musicalGenres_item"
                      key={musicalGenre}
                    >
                      {musicalGenre}
                    </h4>
                  ))}
                </div>
              </div>
            </HashLink>
          ))}
        </div>
      </section>
    );
  };

  const selectAll = () => {
    setMusicalGenreSelected("All");
    setSearch(false);
    setClassName("containerButtonHidden");
    setOpen(false);
  };

  return (
    <>
      <Section
        headerTitle="Booking DJ"
        headerIllustration={`${process.env.PUBLIC_URL}/images/service_booking.jpg`}
        headerAlt="Une illustration de la section Booking Dj"
        descriptionTitle="Faire de votre événement un moment inoubliable"
        descriptionParagraphe={`Nous disposons d’un Roster DJ très varié. Tous nos artistes ont une expérience solide et confirmée en France et à l’étranger, mixant tout type de répertoire musical de la Deep House à l'électro en passant par le Hip Hop, le disco, le R’n’B, le funk, ou le RAP. 
      
      Tout nos DJs sont à découvrir et à réserver pour vos : 
      - lancements de produits
      - soirées privées
      - inaugurations
      - défilés de mode
      - dîners ou autres événements
      
      Pour toute information, faites une demande de rendez-vous depuis notre formulaire de contact ou directement depuis les fiches de nos DJs.`}
        option={<ContactLink />}
      />
      <div className="musicalGenres">
        <button
          type="button"
          className="musicalGenres_title"
          onClick={openMusicalGenres}
        >
          <h3>Genre musical : {musicalGenreSelected}</h3>
        </button>

        <div className={`musicalGenres_${className}`}>
          <button
            className="musicalGenres_containerButton_button"
            type="button"
            onClick={selectAll}
          >
            All
          </button>
          {musicalGenres.map((musicalGenre) => (
            <button
              className="musicalGenres_containerButton_button"
              type="button"
              value={musicalGenre.name}
              onClick={inputChoice}
            >
              {musicalGenre.name}
            </button>
          ))}
        </div>
      </div>
      <SwitchArray />
    </>
  );
}
