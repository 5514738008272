import React from "react";
import PropTypes from "prop-types";
import "./Partenaires.scss";

export default function Partenaires({ href, src, nom }) {
  return (
    <a className="item" href={href}>
      <img className="item_illustration" src={src} alt={nom} />
      <div className="item_description">
        <h4>{nom}</h4>
        <p>Découvrir</p>
      </div>
    </a>
  );
}

Partenaires.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  nom: PropTypes.string.isRequired,
};
