import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./CloseButton.scss";

export default function CloseButton({ linkUrl }) {
  return (
    <Link to={linkUrl}>
      <span className="close_button">&times;</span>
    </Link>
  );
}

CloseButton.propTypes = {
  linkUrl: PropTypes.string.isRequired,
};
